.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  row-gap: 17px;

  header {
    display: grid;
    row-gap: 40px;
    align-items: flex-start;
    text-align: center;

    .heading {
      >h1 {
        font-size: clamp(calc(128rem/16), calc(128vw/7.68), calc(200rem/16));
      }
    }

    .contents {
      display: grid;
      gap: 10px;
    }

    .button {
      display: grid;
      justify-self: center;
    }
  }

  .image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  @media(max-width: 768px) {
    header {
      .button {
        width: 100%;
        padding: 10px 0px;
        justify-content: center;
      }
    }
  }

  @media(max-width: 499px) {
    grid-template-columns: 1fr;
  }
}